import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useMutation } from "@apollo/client";
import { FormSubmission } from "../../../gql/formMutation.gql";
import Link from "next/link";

export interface GravityFormField {
  id: string;
  type: string;
  label: string;
  description?: string;
  placeholder?: string;
  isRequired: boolean;
  choices: {
    isSelected: boolean;
    text: string;
    value: string;
  }[];
}

export interface GravityFormProps {
  id: string;
  fields: Array<GravityFormField>;
}

export type GravityFormData = {
  field: GravityFormField;
  value: string;
  error?: string;
};

const GravityForm: React.FC<GravityFormProps> = ({ id, fields }) => {
  const [formData, setFormData] = useState<GravityFormData[]>(
    fields.map((field) => {
      return { field, value: "" };
    })
  );

  const [formMessage, setFormMessage] = useState(null);

  const [submitForm, { loading, error }] = useMutation(FormSubmission, {
    onError: () => {
      setFormMessage("Oh no! Something went wrong. Please try again. 😞");
    },
    onCompleted: () => {
      setFormMessage("Message sent ✅");
      setTimeout(() => {
        setFormMessage(null);
      }, 5000);
      setFormData(
        fields.map((field) => {
          return { field, value: "" };
        })
      );
    },
  });

  const formHandler = async (event) => {
    event.preventDefault();

    const yupObject = {};

    for (let i = 0; i < formData.length; ++i) {
      yupObject[formData[i].field.label] = formData[i].value;
    }

    let prevState = formData.map((field) => {
      return {
        ...field,
        error: null,
      };
    });

    let isInvalid = false;

    try {
      if (fields.some((field) => field.type === "RADIO")) {
        const radioValidate = await (
          await import("./formValidation")
        ).radioSchema;
        await radioValidate.validate(yupObject, {
          abortEarly: false,
          stripUnknown: true,
        });
      } else {
        const defaultValidate = await (
          await import("./formValidation")
        ).defaultSchema;
        await defaultValidate.validate(yupObject, {
          abortEarly: false,
          stripUnknown: true,
        });
      }
    } catch (e) {
      isInvalid = true;
      e.inner.forEach(({ path, message }) => {
        const target = formData.findIndex(({ field: { label } }) => {
          return label === path;
        });
        if (prevState[target]) prevState[target].error = message;
      });
    }

    setFormData(prevState);

    if (isInvalid) return;

    const inputVariables = formData.map(({ field, value }) => {
      switch (field.type) {
        case "EMAIL":
          return { id: field.id, emailValues: { value: value } };
        default:
          return {
            id: field.id,
            value,
          };
          break;
      }
    });

    await submitForm({
      variables: { Input: { id: +id, fieldValues: inputVariables } },
    });
  };

  if (error)
    <p>
      Error please{" "}
      <Link href="/houston-roofing">
        <a>try again</a>
      </Link>
      .
    </p>;

  return (
    <form className={styles.gravityForm} onSubmit={formHandler}>
      {formData.map(({ field, error, value }, index) => {
        switch (field.type) {
          case "TEXT":
            return (
              <React.Fragment key={field.id}>
                <div className={styles.labelGroup}>
                  <label htmlFor={"input" + field.label}>
                    {field.label}
                    {field.isRequired ? "*" : null}
                  </label>
                  <span>{error}</span>
                </div>
                <input
                  type="text"
                  id={"input" + field.label}
                  name={field.label}
                  onChange={(e) => {
                    setFormMessage(null);
                    const prevFormData = [...formData];
                    prevFormData[index] = { field, value: e.target.value };
                    setFormData(prevFormData);
                  }}
                  placeholder={field.placeholder}
                  value={value}
                />
              </React.Fragment>
            );
          case "PHONE":
            return (
              <React.Fragment key={field.id}>
                <div className={styles.labelGroup}>
                  <label htmlFor={"input" + field.label}>
                    {field.label}
                    {field.isRequired ? "*" : null}
                  </label>
                  <span>{error}</span>
                </div>
                <input
                  type="tel"
                  id={"input" + field.label}
                  name={field.label}
                  onChange={(e) => {
                    setFormMessage(null);
                    const prevFormData = [...formData];
                    prevFormData[index] = { field, value: e.target.value };
                    setFormData(prevFormData);
                  }}
                  placeholder={field.placeholder}
                  value={value}
                />
              </React.Fragment>
            );
          case "EMAIL":
            return (
              <React.Fragment key={field.id}>
                <div className={styles.labelGroup}>
                  <label htmlFor={"input" + field.label}>
                    {field.label}
                    {field.isRequired ? "*" : null}
                  </label>
                  <span>{error}</span>
                </div>
                <input
                  type="email"
                  id={"input" + field.label}
                  name={field.label}
                  onChange={(e) => {
                    setFormMessage(null);
                    const prevFormData = [...formData];
                    prevFormData[index] = { field, value: e.target.value };
                    setFormData(prevFormData);
                  }}
                  placeholder={field.placeholder}
                  value={value}
                />
              </React.Fragment>
            );
          case "RADIO":
            return (
              <React.Fragment key={field.id}>
                <div className={styles.labelGroup}>
                  <label htmlFor={"input" + field.label}>
                    {field.label}
                    {field.isRequired ? "*" : null}
                  </label>
                  <span>{error}</span>
                </div>
                <div className={styles.radioGroup}>
                  {field.choices.map((choice, key) => {
                    return (
                      <div className={styles.radioButton} key={key}>
                        <label htmlFor={"input" + field.label + key}>
                          <span>{choice.text}</span>
                          <input
                            type="radio"
                            id={"input" + field.label + key}
                            name={field.label}
                            value={choice.value}
                            onChange={(e) => {
                              setFormMessage(null);
                              const prevFormData = [...formData];
                              prevFormData[index] = {
                                field,
                                value: e.target.value,
                              };
                              setFormData(prevFormData);
                            }}
                            placeholder={field.placeholder}
                          />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          case "TEXTAREA":
            return (
              <React.Fragment key={field.id}>
                <div className={styles.labelGroup}>
                  <label htmlFor={"input" + field.label}>
                    {field.label}
                    {field.isRequired ? "*" : null}
                  </label>
                  <span>{error}</span>
                </div>
                <textarea
                  id={"input" + field.label}
                  name={field.label}
                  onChange={(e) => {
                    setFormMessage(null);
                    const prevFormData = [...formData];
                    prevFormData[index] = { field, value: e.target.value };
                    setFormData(prevFormData);
                  }}
                  placeholder={field.placeholder}
                  value={value}
                  rows={5}
                ></textarea>
              </React.Fragment>
            );
        }
      })}

      <div className={styles.buttonGroup}>
        <span className={styles.sucess}>
          {formMessage ? formMessage : null}
        </span>
        <button type="submit">{loading ? "Submitting..." : "Submit"}</button>
      </div>
    </form>
  );
};

export default GravityForm;

//TODO set formMessage, set clear inputs
