import { gql } from "@apollo/client";

const FormSubmission = gql`
  mutation FormSubmission($Input: SubmitGfFormInput!) {
    submitGfForm(input: $Input) {
      errors {
        id
        message
      }
    }
  }
`;

export { FormSubmission };
